/*
 * 업무구분: 수익증권>전체메뉴
 * 화 면 명: MSPFS010P
 * 화면설명: 수익증권 전체메뉴
 * 작 성 일: 2023.04.04
 * 작 성 자: 송진의
 */
<template>
  <!-- popup : 전체메뉴 -->
  <mo-modal class="fts-modal modal-full menu-global" ref="menuGlobal" title="전체메뉴" full-screen no-foot>
    <template>
      <ur-box-container direction="row">
        <ul class="gnb-list d1">
          <li v-for="(lv1, index) in lv_pageObj.filter(p=> p.level === 'L')" :key="index" >
            <dl>
              <dt>
                <strong>{{lv1.text}}</strong>
              </dt>
              <dd>
                <ul class="d2">
                  <li v-for="(lv2, index) in lv_pageObj.filter(p=> p.level === 'M' && p.lv1 === lv1.value)" :key="index">
                    <dl>
                      <dt>
                        <em>{{lv2.text}}</em>
                      </dt>
                      <dd>
                        <ul class="d3" v-for="(lv3, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === lv2.value)" :key="index">
                          <li>
                            <a href="javascript:void(0)" @click="fn_GoPage(lv3)">{{lv3.text}}</a>                            
                          </li>                            
                        </ul>
                      </dd>
                    </dl>
                  </li>                    
                </ul>
              </dd>
            </dl>              
          </li>            
        </ul>
      </ur-box-container>
    </template>
    <template slot="action"></template>
    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSMenuUtil from '~/src/ui/fs/comm/FSMenuUtil'
import FSAlertPopup from '@/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS010P",
  screenId: "MSPFS010P",
  components: {
    'fs-alert-popup': FSAlertPopup,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_InitMenu()
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS010P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      pAlertPopupObj: {},
      lv_pageObj: [],
      lv_selPageObj: {},
      lv_isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

      // 녹취 관련 
      busnScCd: '', //업무구분코드
      recdYmd: '', //녹취일자
      recdStrTm: '', //녹취시작시간
      recdGudeEno: '', //녹취안내자사번
      recdTotPhclTm: '', //녹취총통화시간
      recdFileNm: '', //녹취파일명
      custId: '', //고객ID
      custNm: '', //고객명
      custRrnEncr: '', //고객주민등록번호암호화
      custTelnoEncr: '', //고객전화번호암호화
      nrmTrtYn: 'Y', //정상처리여부
      lstTrtYn: 'N', //최종처리여부
      recdTrtDtm: '', //녹취처리일시
      currDt: '', //녹취시작일시
      endDt: '', //녹취종료일시        
      doubleUploadFlag_NAS: false, // 녹취파일 중복 업로드 체크      
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    menuGlobal() {
      return this.$refs.menuGlobal
    },
    isRecord() {
      return this.getStore('fsStore').getters.getState.isRecording
    },
    getRecInfo() {        
      return this.getStore('fsStore').getters.getBasInfo.data.recInfo
    },
    getCustInfo() {        
      return this.getStore('fsStore').getters.getBasInfo.data.custInfo
    },
    getType() {        
      return this.getStore('fsStore').getters.getBasInfo.data.procTyp
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {    
    /******************************************************************************
     * Function명 : fn_InitMenu
     * 설명       : 메뉴 초기화
     ******************************************************************************/
    fn_InitMenu() {
      this.lv_pageObj = FSMenuUtil.gfn_InitMenu()

      // 전체메뉴 여부 초기화
      this.getStore('fsStore').dispatch('IS_SNB_STOP')
    },
    /******************************************************************************
     * Function명 : fn_GoPage
     * 설명       : 페이지 이동
     * 파라미터(pageObj): page 객체 정보
     ******************************************************************************/
    async fn_GoPage(pageObj) {
      this.lv_selPageObj = pageObj
      if (this.$route.name === 'MSPFS000M') {
        this.fn_RptAftPage()
        return
      } else {
        let recStat = await this.fn_GetRecStat()
        console.log('현재 녹취 상태 [MSPFS010P - fn_GoPage]: ' , recStat)
        if(recStat === 'Playing') {
          this.fn_endRecord()
        }
      }
      
      let t_formInfo = this.getStore('fsStore').getters.getBasInfo.data.formInfo
      let t_type = 0
      
      // 1. 리포트 있을 때,
      if(!this.$bizUtil.fsUtils.isEmptyObj(t_formInfo)){
        this.getStore('fsStore').dispatch('IS_SNB_START') // 리포트가 있을 때만, 전체메뉴 여부 체크
        this.fn_Close()        
        t_type = 9
        let t_popupObj = {
          btnType: 2,
          confirm: true,
          confirmFunc: this.fn_ReportPrint,
          content: ['*중단된 업무는 재진입 시 처음부터','다시 진행해야 합니다.'],
          contentTitle: '화면이동 시 현재 업무가 중단됩니다. 이동하시겠습니까?',
        }
        this.fn_AlertPopup(t_type, t_popupObj)
      } else { // 2. 리포트 없을 때,        
        this.fn_RptAftPage()
      }
      return
    },

    async fn_GetRecStat() {            
      if(this.lv_isMobile){
        return await this.$commonUtil.getRecStatus(this)
      } else {        
        return this.isRecord ? 'Playing' : 'Stop'
      }
    },

    /******************************************************************************
    * Function명 : fn_ReportPrint
    * 설명       : 중단 시 전자서식 출력
    ******************************************************************************/
    async fn_ReportPrint() {        
      FSInfoUtil.commReportInfo(this, [], this.fn_RptAftPage, true, this.$route.name, true)
    },
    
    // 리포트 후 페이지 이동
    fn_RptAftPage() {
      let t_pageObj = this.lv_selPageObj
      // 스텝 여부
      if(t_pageObj.params.isStep) {        
        let t_IsProcess = this.getStore('fsStore').getters.getState.isProcess        
        let procTyp =  this.getStore('fsStore').getters.getBasInfo.data.procTyp
        if ( !t_IsProcess ) {
          this.getStore('fsStore').dispatch('IS_PROCESS_START')
          this.$bizUtil.fsUtils.saveProcTyp(this, t_pageObj.params.procTyp)
        } else {
          if (t_pageObj.params.procTyp !== procTyp) {
            this.$bizUtil.fsUtils.saveProcTyp(this, t_pageObj.params.procTyp)
          }
        } 
      } else {        
        this.$bizUtil.fsUtils.saveProcTyp(this)
        this.getStore('fsStore').dispatch('IS_PROCESS_STOP')
      }

      // 페이지 이동 시
      // 1. 동일한 페이지ID 가 아닐 경우
      if(!this.$route.name.includes(t_pageObj.page)) {
        this.$router.push({
          name: t_pageObj.page,
          params: t_pageObj.params
        })
      }

      // 창 닫기
      this.fn_Close()
      this.lv_selPageObj = {}
    },

    // 팝업 열기
    fn_Open() {
      this.$refs.menuGlobal.open();
    },
    // 팝업 닫기
    fn_Close() {
      this.$refs.menuGlobal.close();
    },

    /******************************************************************************
      * Function명 : fn_AlertPopup
      * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        this.pAlertPopupObj.content = pPopupObj.content          
        this.pAlertPopupObj.confirm = false
        this.pAlertPopupObj.cancel = true
        this.pAlertPopupObj.type = 0
        this.pAlertPopupObj.contentTitle = ''
        this.pAlertPopupObj.btnType = 0

        if( !pPopupObj.cancel ) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel
        }
        if( pPopupObj.confirm ) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData
        }
        if( pPopupObj.closeFunc ) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
          this.pAlertPopupObj.closeData = pPopupObj.closeData
        }
        if( !FSCommUtil.gfn_isNull(type) && type != 0 ) {
          this.pAlertPopupObj.type = type
        }
        if( pPopupObj.contentTitle ) {
          this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle
        }

        if( pPopupObj.btnType ) {
          this.pAlertPopupObj.btnType = pPopupObj.btnType
        }
      }
      this.$refs.alertPopup.fn_Open()
    },

      /******************************************************************************
    * Function명 : fn_strToDate
    * 설명       : 녹취 시간 
    ******************************************************************************/   
    fn_strToDate(sDate){
      let nYear = parseInt(sDate.substr(0, 4));
      let nMonth = parseInt(sDate.substr(4, 2)) - 1;
      let nDate = parseInt(sDate.substr(6, 2));
      
      let nHour = parseInt(sDate.substr(8, 2));
      let nMin = parseInt(sDate.substr(10, 2));
      let nSec = parseInt(sDate.substr(12, 2));
      
      return new Date(nYear, nMonth, nDate, nHour, nMin, nSec);
    },

    /******************************************************************************
    * Function명 : fn_getCalRecTotTime
    * 설명       : 녹취 시간 계산
    ******************************************************************************/
    fn_getCalRecTotTime(dateTime) {        
      let currDtOfEnd = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')
      let nDiffDate = this.fn_strToDate(currDtOfEnd) - this.fn_strToDate(dateTime)
      return parseInt(nDiffDate / 1000)
    },

    /******************************************************************************
    * Function명 : fn_endRecord
    * 설명       : 녹취 종료 액션
    ******************************************************************************/   
    fn_endRecord() {        
      if(this.$bizUtil.isEmpty(this.getRecInfo)){
        return
      }

      // 동일한 프로세스, 화면 에서 시작을 하면 재종료 하지 않음
      console.log('fn_endRecord...', this.getRecInfo)
      let t_procTyp = !this.$bizUtil.isEmpty(this.lv_selPageObj.procTyp) ? this.lv_selPageObj.procTyp : ''
      if(this.getRecInfo.recdId === (this.lv_selPageObj.page + t_procTyp)) {
        return
      }

      if(process.env.NODE_ENV === 'local'){
        this.$addSnackbar('녹취가 종료되었습니다.')
        this.getStore('fsStore').dispatch('IS_RECORDING')
        this.recdTotPhclTm = this.fn_getCalRecTotTime(this.getRecInfo.currDt)
        this.fn_transRecodeFile()
        this.fn_insRecdFile()
      }

      console.log('녹취 종료')
      this.recdTotPhclTm = ''
      window.fdpbridge.exec('RecordStopPlugin', {fileNm: this.getRecInfo.recdFileNm}, 
      () => { // success        
        this.recdTotPhclTm = this.fn_getCalRecTotTime(this.currDt)          
        this.$addSnackbar('녹취가 종료되었습니다.')
        this.fn_transRecodeFile()
      }, () => { // fail
        this.getStore('fsStore').dispatch('IS_RECORDING_STOP')
        this.$addSnackbar('녹취가 정상적으로 종료되지 않았습니다.')
      })
    },

    /******************************************************************************
    * Function명 : fn_transRecodeFile
    * 설명       : 녹취 화일 NAS로 전송
    ******************************************************************************/       
    async fn_transRecodeFile() {
      // 녹취파일 업로드 중복처리 방지
      if ( this.doubleUploadFlag_NAS ) {
        this.$addSnackbar("녹취화일을 전송 중 입니다.")
        return
      }
      let lv_vm = this
      let jobPath = "REC_FILE_DIR_FS"      
      let t_Param = {
        serverurl:jobPath,
        fileNm: this.getRecInfo.recdFileNm, // 저장파일명
      }          
      // this.fn_ShowLoading(true, '2') // 화면 로딩 처리 // 화면 로딩
      lv_vm.doubleUploadFlag_NAS = true
        window.fdpbridge.exec('RecordUploadPlugin', t_Param, function (result) {
          console.log('RecordUploadPlugin::result::::', JSON.stringify(result))
          lv_vm.doubleUploadFlag_NAS = false            
          if (result.data === '200' || result.data === 'ok') {
            lv_vm.fn_insRecdFile() //녹취파일 정상           
          } else {
            lv_vm.fn_insRecdFile(1) //녹취파일 오류
          } // end else if
        }, function (result) {
            lv_vm.fn_insRecdFile(2) //녹취파일 오류              
        })
    },

    /******************************************************************************
    * Function명 : fn_insRecdFile
    * 설명       : 녹취 화일 저장 오류 처리
    ******************************************************************************/       
    async fn_insRecdFile (err) {
      let nrmTrtYn = ''      
      if (this.$bizUtil.isEmpty(err)) {
        if(process.env.NODE_ENV !== 'local'){
          await this.$commonUtil.delRecData(this, this.getRecInfo.recdFileNm)
        }
        nrmTrtYn = 'Y'
      } else {
        nrmTrtYn = 'N'
      }
      
      let recdTrtDtm = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')        
      let custData = {
        custId: '',
        custNm: '',
        rrnAesEncr: '',
        custTelnoEncr: ''
      }

      let tmpCustData = {
        custId: '9999900000',
        custNm: '비추미',
        rrnAesEncr: '9999990000000',
        custTelnoEncr: '01099990000',
      }

      if(process.env.NODE_ENV !== 'local'){
        if(!this.$bizUtil.isEmpty(this.getCustInfo)) {
          custData.custId = !this.$bizUtil.isEmpty(this.getCustInfo.cdId)? this.getCustInfo.cdId : tmpCustData.custId
          custData.custNm = !this.$bizUtil.isEmpty(this.getCustInfo.csNm)? this.getCustInfo.csNm : tmpCustData.custNm
          custData.rrnAesEncr = !this.$bizUtil.isEmpty(this.getCustInfo.prftBnfcRcno)? this.getCustInfo.prftBnfcRcno : tmpCustData.rrnAesEncr            
          custData.custTelnoEncr = !this.$bizUtil.isEmpty(this.getCustInfo.celph)? this.getCustInfo.celph : tmpCustData.custTelnoEncr
        } else { // 데이터가 없을 때 임시 데이터 입력
          custData.rrnAesEncr = tmpCustData.rrnAesEncr
          custData.custTelnoEncr = tmpCustData.custTelnoEncr
          custData.custId = tmpCustData.custId
          custData.custNm = tmpCustData.custNm
        }         
        try {
          custData.rrnAesEncr = await this.$commonUtil.getEncriptData(this, custData.rrnAesEncr)
          custData.custTelnoEncr = await this.$commonUtil.getEncriptData(this, custData.custTelnoEncr)            
          custData.rrnAesEncr = custData.rrnAesEncr.trim().replace(/\n/g, '')
          custData.custTelnoEncr = custData.custTelnoEncr.trim().replace(/\n/g, '')
        } catch (e) {
          window.vue.error(e)  
        }
      } else {
        custData.rrnAesEncr = tmpCustData.rrnAesEncr
        custData.custTelnoEncr = tmpCustData.custTelnoEncr
      }
      let lv_vm = this
      let pParams = {
        busnScCd: this.getRecInfo.busnScCd,
        recdFileNm: this.getRecInfo.recdFileNm + '.mp3.crypt',
        recdStrTm: this.getRecInfo.recdStrTm,
        recdGudeEno: this.getRecInfo.recdGudeEno,
        recdYmd: this.getRecInfo.recdYmd,
        recdTotPhclTm: this.recdTotPhclTm,
        custId: custData.custId,
        custNm: custData.custNm,
        rrnAesEncr: custData.rrnAesEncr,   ////////////////// 차후 변경 this.custRrnEncr
        custTelnoEncr: custData.custTelnoEncr,   ////////////////// 차후 변경 this.custTelnoEncr
        nrmTrtYn: nrmTrtYn,
        lstTrtYn: this.lstTrtYn,
        recdTrtDtm: recdTrtDtm
      }        
      // alert('fn_insRecdFile - param : ' + JSON.stringify(pParams))
      window.vue.post(lv_vm, pParams, 'txTSSBC99I1', '')
        .then((response) => {
          try {
            // alert('fn_insRecdFile - response : ' + JSON.stringify(response))
            lv_vm.getStore('fsStore').dispatch('IS_RECORDING_STOP')
            console.log("insert response....................."+JSON.stringify(response));
          } catch (e) {
            window.vue.error(e)  
          } 
        })
        .catch((error) => {
          window.vue.error(error)
        })
    },
    
    /************************************************************************************************
      * Function명  : fn_ShowLoading
      * 설명        : 화면 로딩 처리 공통 메소드
      ************************************************************************************************/
    fn_ShowLoading: function (isShow, showType = ' ') {
      console.log('this.fn_ShowLoading Call', 'showType : ' + showType)
      this.isShowLoading = isShow // 화면 로딩
      if (showType === '0') {
        this.isShowLoading1 = false // 진행 중
        this.isShowLoading2 = false // 처리 중
      } else if (showType === '1') {
        this.isShowLoading1 = true // 진행 중
        this.isShowLoading2 = false // 처리 중
      } else if (showType === '2') {
        this.isShowLoading1 = false // 진행 중
        this.isShowLoading2 = true // 처리 중
      } // end else if
    },
  }
};
</script>
<style scoped>
</style>